<template>
  <a-form-model ref="form" :model="form" v-bind="$formItemLayout">
    <a-form-model-item
      label="学员名称："
      prop="username"
      :rules="{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }"
    >
      <a-input v-model.trim="form.username" :maxLength="25" />
    </a-form-model-item>
    <a-form-model-item
      label="性别："
      :rules="{
        required: true,
        message: '请选择',
        trigger: 'blur'
      }"
      prop="sex"
    >
      <a-radio-group v-model="form.sex">
        <a-radio v-for="statusInfo in statusOptions" :key="statusInfo.value" :value="statusInfo.value">
          {{ statusInfo.label }}
        </a-radio>
      </a-radio-group>
    </a-form-model-item>

    <a-form-model-item label="年龄：" prop="birthday">
      <a-date-picker v-model="form.birthday" valueFormat="YYYY-MM-DD" style="flex:1;width: 100%" />
    </a-form-model-item>
    <a-form-model-item
      label="机构："
      prop="mechanismId"
      :rules="{
        required: true,
        message: '请选择',
        trigger: 'blur'
      }"
    >
      <a-cascader
        :options="mechanismOptions"
        change-on-select
        v-model="defaultMechanism"
        @change="onChange"
        :fieldNames="{ label: 'name', value: 'id', children: 'childrenList' }"
        placeholder="请选择"
        :showSearch="true"
        :disabled="isDisabled"
      >
      </a-cascader>
    </a-form-model-item>
    <a-form-model-item label="联系电话：" prop="cellphone" :rules="checkPhoneRule">
      <a-input v-model.trim="form.cellphone" :maxLength="11" />
    </a-form-model-item>

    <a-form-model-item label="设备号：" prop="machineCode">
      <span v-if="isDisabled">{{ form.machineCode || '--' }}</span>
      <a-input v-else :disabled="isDisabled" v-model.trim="form.machineCode" :maxLength="20" />
    </a-form-model-item>
    <a-form-model-item
      label="监护人："
      prop="guardians"
      :rules="{
        required: true,
        message: '请选择',
        trigger: 'blur'
      }"
      style="margin-bottom: 64px"
    >
      <a-input v-model.trim="form.guardians" />
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { cloneDeep } from 'lodash'
import { GENDER_TYPE } from '@/store/dictionary'
import * as mechanismApi from '@/api/mechanism'

export default {
  data() {
    const MobileNumberValidator = (rule, value, callback) => {
      const idcardReg = /^1(3|4|5|6|7|8|9)\d{9}$/
      if (!idcardReg.test(value)) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入正确的手机号码')
      }
      callback()
    }
    return {
      checkPhoneRule: [
        { validator: MobileNumberValidator, max: 11, trigger: ['change', 'blur'] },
        {
          required: true,
          message: '请输入',
          trigger: ['change', 'blur']
        }
      ],
      form: {
        username: '',
        sex: '',
        birthday: '',
        cellphone: '',
        machineCode: '',
        mechanismId: '',
        nickname: '', // 昵称
        guardians: '' // 监护人
      },
      statusOptions: GENDER_TYPE, // 性别列表
      mechanismOptions: [], // 机构列表
      defaultMechanism: [], // 选中的机构
      isDisabled: false
    }
  },
  mounted() {
    const {
      $route: {
        query: { isDisabled }
      }
    } = this
    this.isDisabled = JSON.parse(isDisabled)
    // 获取结构列表
    mechanismApi.mechanismOptions().then(res => {
      this.mechanismOptions = res.data
    })
  },
  methods: {
    initData(form, defaultMechanism) {
      this.form = Object.assign({}, this.form, form)
      if (defaultMechanism) {
        this.defaultMechanism = defaultMechanism
      }
    },
    validate() {
      const {
        $refs: { form }
      } = this

      return new Promise((resolve, reject) => {
        form.validate((valid, err) => {
          if (valid) {
            resolve(valid)
            return
          }
          reject(err)
        })
      })
    },
    formatForm() {
      try {
        const ret = cloneDeep(this.form)
        return { ...ret }
      } catch {}
    },
    onChange(record) {
      this.defaultMechanism = record
      this.$set(this.form, 'mechanismId', record[record.length - 1])
    }
  }
}
</script>

<style lang="less" scoped></style>
