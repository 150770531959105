<template>
  <a-card>
    <div slot="title">编辑学员</div>
    <basis-form ref="basis" />
    <a-row>
      <a-col v-bind="$formItemLayoutWithoutLabel.wrapperCol">
        <a-button type="primary" shape="round" style="width: 160px" @click="onSubmit"> 确认 </a-button>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import BasisForm from './components/BasisForm'
import * as Api from '@/api/student'
import { checkDateIsBeforeEqual } from '@/utils/util'
import moment from 'moment'
export default {
  name: 'StudentManagementEdit',
  components: {
    BasisForm
  },
  data() {
    return {
      studentInfo: {}
    }
  },
  watch: {
    $route(newValue, oldValue) {
      if (this.$route.name === 'StudentManagementEdit') {
        this.initData()
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const {
        $route: {
          params: { id }
        }
      } = this
      Api.getStudentDetail({ studentId: id }).then(res => {
        const { data } = res
        const {
          username,
          nickname,
          sex,
          birthday,
          cellphone,
          machineCode,
          guardians,
          mechanismVO: { id: mechanismId, parent }
        } = data
        this.studentInfo = data
        const mechanismArr = [mechanismId]
        if (parent) {
          if (parent.id !== 1) {
            mechanismArr.unshift(parent.id)
          }
        }

        this.$refs.basis.initData(
          { id, username, sex, birthday, cellphone, machineCode, mechanismId, nickname, guardians },
          mechanismArr
        )
      })
    },
    onSubmit() {
      const {
        $refs: { basis },
        $notification
      } = this
      basis.validate().then(valid => {
        if (valid) {
          this.loading = true
          try {
            const { formatForm } = basis
            const form = formatForm()
            const { id, birthday } = form
            if (checkDateIsBeforeEqual(birthday, moment(new Date()).format('YYYY-MM-DD'))) {
              this.$notification.error({
                message: '错误',
                description: '请选择过去的日期'
              })
              return
            }

            if (id) {
              const { schoolStatus } = this.studentInfo
              const _form = { ...form, ...{ schoolStatus } }
              Api.studentUpdate(_form)
                .then(res => {
                  const { code, msg } = res
                  if (code === 0) {
                    $notification['success']({ message: '操作成功' })
                    this.close()
                  } else {
                    this.$notification['error']({ message: '提示', description: `${msg}` })
                  }
                })
                .finally(() => (this.loading = false))
            }
          } catch {
            $notification['error']({ message: '提示', description: '脚本错误' })
            this.loading = false
          }
        }
      })
    },
    close() {
      const { current, pageSize } = this.$route.query
      this.$router.push({ name: 'StudentManagementList', query: { current, pageSize } })
    }
  }
}
</script>

<style lang="less" scoped></style>
